import { route } from 'quasar/wrappers';
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouterScrollBehavior,
} from 'vue-router';

import routes from './routes';

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
    ? createWebHistory
    : createWebHashHistory;

  const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {
    // Check if only the query parameters changed (same path, different query)
    const isOnlyQueryChange = to.path === from.path && to.hash === from.hash;

    // Check if the navigation is caused by a q-tab click
    const isQTabNavigation = to.matched.some(record =>
      record.components?.default && (record.components.default as any).isQTabNavigation
    );

    // If only query parameters changed, don't scroll
    if (isOnlyQueryChange) {
      return false;
    }
    // If there's a hash and the element exists, scroll to it
    else if (to.hash && document.querySelector(to.hash)) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    }
    // If there was a saved position, return it
    else if (savedPosition) {
      return savedPosition;
    }
    // If it's a q-tab navigation, don't scroll
    else if (isQTabNavigation) {
      return false;
    }
    // Default behavior: scroll to the top
    return { left: 0, top: 0 };
  };

  const Router = createRouter({
    history: createHistory(process.env.VUE_ROUTER_BASE),
    routes,
    scrollBehavior,
  });

  return Router;
});
